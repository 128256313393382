export const changeYourEmailAddress = {
  order: 3,
  name: "Change your email address",
  subcategory: "Manage your account",
  markdown: `# Change your email address
  Did you change companies or otherwise lose access to the email inbox associated with your Tower Hunt account? Read on for steps to change your address.

  {% tablessContainer %}
  1. [Sign in to Tower Hunt](/sign-in).
  2. Click your initials in the upper-right and choose **My Account** from the menu.
  3. Under **Personal Info**, click **Update** to the right of your email.
  4. Enter your password to verify that it's you.
  5. Type a new email address that you control and click **Submit**.
  6. {% inlineRouterLink articleId="verify-your-email-address" %}Verify your new email address{% /inlineRouterLink %}.
  {% /tablessContainer %}`,
};
